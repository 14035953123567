import allocationService from "@/services/AllocationService.js";

const timeframeFilter = {
  namespaced: true,
  state: {
    data: null,
    options: []
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_OPTIONS(state, options) {
      state.options = options;
    },
  },
  actions: {
    setData(context, data) {
      context.commit("SET_DATA", data);
    },
    reset({commit}) {
      commit("SET_OPTIONS", []);
      commit("SET_DATA", null);
    },
    getOptions({ commit, state, rootGetters, dispatch}, refresh) {
      if (refresh || state.options.length === 0) {
        const reportFiltering = rootGetters["reports/reportFilter/toParams"];
        const params = {
          pagination: {page: 1, itemsPerPage: 1000 },
          sorting: { sortBy: ["timeFrame.index"], sortDesc: [false]},
          search: [reportFiltering]
        };
        allocationService.reportTimeframes.list(params)
          .then(json => {
            commit("SET_OPTIONS", json.items);
            if (json.items.length) {
              commit("SET_DATA", json.items[0].timeFrame.id);
              dispatch("reports/fetchGeneratedReport", true, {root: true});
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
  },
  getters: {
    data: state => {
      return state.data;
    },
    options: state => {
      return state.options.map(option => {
        return {
          text: option.timeFrame.timeframe,
          value: option.timeFrame.id,
        };
      });
    },
    toParams: state => {
      return { column: "timeFrame.id", value: state.data };
    },
    selectedOptionByValue: state => {
      return state.options.find(option => option.timeFrame.id === state.data) ?? null;
    }
  }
};

export default timeframeFilter;
