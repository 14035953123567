import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import allocationService from "@/services/AllocationService.js";
import rulesOverview from "@/store/modules/plansOverview/rulesOverview.js";
import conditionsOverview from "@/store/modules/plansOverview/conditionsOverview.js";
import skusOverview from "@/store/modules/plansOverview/skusOverview.js";
import Vue from 'vue';
import i18n from "@/plugins/i18n";

const plansOverview = {
  requestController: null,
  namespaced: true,
  modules: {
    rulesOverview,
    conditionsOverview,
    skusOverview
  },
  state: {
    ...tableState,
    plan: null,
    updatingSortOrder: false,
    dryRuns: {},
    dryRunError: {},
    sideMenuCollapsed: false,
    selectedDryRun: false
  },
  mutations: {
    ...tableMutations,
    SET_SIDE_MENU_COLLAPSED(state, value) {
      state.sideMenuCollapsed = value;
    },
    SET_ITEMS(state, data) {
      state.items = data.items;
      state.totalItems = data.totalItems;
    },
    ADD_PLAN(state, plan) {
      state.items.push(plan);
      state.totalItems = state.items.length;
    },
    REMOVE_PLAN(state, planId) {
      state.items = state.items.filter(planItem => planItem.id !== planId);
    },
    SET_UPDATING_ORDER(state, value) {
      state.updatingSortOrder = !!value;
    },
    ADD_PLAN_RULE (state, rule) {
      rule.rule = rulesOverview.state.items.find(ruleObj => rule.rule === ruleObj['@id']);
      rule.condition = conditionsOverview.state.items?.find(condition => condition['@id'] === rule.condition);
      state.item.planRules.push(rule);
    },
    UPDATE_PLAN_RULE (state, rule) {
      const matchingRule = state.item.planRules.find(planRule => rule.id === planRule.id);
      rule.rule = matchingRule.rule;
      rule.condition = conditionsOverview.state.items?.find(condition => condition['@id'] === rule.condition);
      Object.assign(matchingRule, rule);
    },
    DELETE_PLAN_RULE (state, ruleId) {
      Vue.delete(state.item.planRules, state.item.planRules.findIndex(planRule => planRule.id === ruleId));
    },
    MAP_DRY_RUN (state, dryRun) {
      const matchingDryRun = state.dryRuns[dryRun.planId].find(dryRunObj => dryRunObj.id === dryRun.id);
      if (dryRun.result) {
        const tickets = Object.values(dryRun.result.ticketCollection?.tickets ?? []);
        matchingDryRun.result = dryRun.result;
        matchingDryRun.mapping = { rules: [], ticketLocations: tickets.map(ticket => ticket.ticketLocations).flat() };
        if (tickets.length > 0) {
          state.item.planRules.forEach(planRule => {
            let ticketObj = { rule: structuredClone(planRule), tickets: [] };
            Object.keys(dryRun.result.ticketCollection?.tickets).forEach(ticketId => {
              const ticket = dryRun.result.ticketCollection.tickets[ticketId];
              let matchingTicketHistoryRule = ticket.ticketHistory?.find(tickHistory => tickHistory.rule === planRule.rule.alias);
              if (matchingTicketHistoryRule) {
                ticketObj.rule.priority = matchingTicketHistoryRule.priority;
                ticketObj.tickets.push({
                  id: ticketId,
                  sku: ticket.requestItem.sku,
                  input: matchingTicketHistoryRule.data.input ? Object.keys(matchingTicketHistoryRule.data.input) : [],
                  output: matchingTicketHistoryRule.data.output ? Object.keys(matchingTicketHistoryRule.data.output) : []
                });
              }
            });
            matchingDryRun.mapping.rules.push({ ...ticketObj });
          })
        }
      }
      if (state.selectedDryRun) {
        state.selectedDryRun.mapping = matchingDryRun.mapping;
        state.selectedDryRun.result = matchingDryRun.result;
      }
    },
    ADD_DRY_RUN (state, dryRun) {
      if (state.dryRuns[state.item.id]) {
        state.dryRuns[state.item.id].push(dryRun);
      } else {
        Vue.set(state.dryRuns, state.item.id, [ dryRun ]);
      }
    },
    PURGE_DRY_RUNS (state) {
      if (state.dryRuns[state.item.id]) {
        state.dryRuns[state.item.id] = [];
      }
    },
    PURGE_DRY_RUNS_BY_PLAN_ID (state, planId) {
      if (state.dryRuns[planId]) {
        state.dryRuns[planId] = [];
      }
    },
    SELECT_DRY_RUN (state, dryRun) {
      state.selectedDryRun = dryRun;
    },
    SET_DRY_RUN_ERROR (state, error) {
      state.dryRunError = error;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);
        const params = { pagination: context.state.pagination, sorting: context.state.sorting, search: context.state.search };

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        allocationService.plans.list(params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              context.commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            context.commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
          context.commit("SET_LOADING", false);
        });
      }
    },
    searchItems(context) {
      context.dispatch("fetchItems", true);
    },
    savePlanAs(context, { oldPlan, newPlan }) {
      return new Promise((resolve, reject) => {
        allocationService.plans.create(newPlan)
            .then((result) => {
              context.commit("SET_ITEM", result);
              if (oldPlan && oldPlan.planRules.length > 0) {
                const promises = oldPlan.planRules.map(planRule => context.dispatch('rulesOverview/addPlanRule', { plan: result, rule: planRule.rule }))
                Promise.all(
                    promises
                ).then(() => {
                  context.commit("ADD_PLAN", result);
                  resolve();
                });
              } else {
                context.commit("ADD_PLAN", result);
                resolve();
              }
            })
            .catch(err => {
              console.log(err);
              reject(err["hydra:description"]);
            });
      });
    },
    addPlan(context, plan) {
      return new Promise((resolve, reject) => {
        allocationService.plans.create(plan)
            .then((result) => {
              context.commit("SET_ITEM", result);
              context.commit("ADD_PLAN", result);
              resolve();
            })
            .catch(err => {
              reject(err["hydra:description"]);
            })
      });
    },
    editPlan(context, plan) {
      return new Promise((resolve, reject) => {
        allocationService.plans.update(plan.id, plan)
          .then((result) => {
            context.commit("SET_ITEM", result);
            context.dispatch("fetchItems", true);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    deletePlan(context, id) {
      return new Promise((resolve, reject) => {
        allocationService.plans.delete(id)
          .then(() => {
            context.commit("SET_ITEM", null);
            context.commit("REMOVE_PLAN", id);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    updatePlanRulesOrder(context, { rules, plan }) {
      return new Promise((resolve, reject) => {
        context.commit("SET_UPDATING_ORDER", true);
        const updatedRules = [];
        const backup = structuredClone(plan.planRules);
        rules.forEach((planRule, index) => {
          if (planRule && context.getters.sortedPlanRules[index] && context.getters.sortedPlanRules[index].id !== planRule.id) {
            updatedRules.push(planRule);
          }
        })
        updatedRules.map((updatedRule, index) => updatedRule.priority = updatedRules.length - index)
        const promises = updatedRules.map(planRule => context.dispatch('rulesOverview/editPlanRule', {
          plan,
          rule: planRule
        }))
        Promise.all(promises).then((result) => {
          context.commit("SET_UPDATING_ORDER", false);
          resolve(result);
        }).catch(err => {
          plan.planRules = backup;
          context.commit("SET_UPDATING_ORDER", false);
          reject(err["hydra:description"]);
        });
      });
    },
    runPlan(context, params) {
      return new Promise((resolve, reject) => {
        allocationService.dry_run.create(
            {
              plan: params.plan,
              date: null,
              skus: params.skus
            }
        ).then((result) => {
          context.commit('MAP_DRY_RUN', {
            planId: params.planId,
            id: params.dryRunId,
            name: "Run #" + params.dryRunId,
            result: result
          })
          resolve(result);
        }).catch(err => {
          console.log(err);
          context.commit("SET_DRY_RUN_ERROR");
          reject(err["hydra:description"]);
        })
      });
    }
  },
  getters: {
    ...tableGetters,
    itemsWithNewPlan: state => {
      let items = JSON.parse(JSON.stringify(state.items));
      items.push({
        id: "new",
        name: i18n.t("plan_builder.use_new_plan")
      });
      return items;
    },
    activeFromDt: state => {
      if (state.item && state.item.activeFromDt != null) {
        return new Date(
          new Date(state.item.activeFromDt) -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 10);
      }
      return null;
    },
    activeUntilDt: state => {
      if (state.item && state.item.activeUntilDt != null) {
        return new Date(
          new Date(state.item.activeUntilDt) -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 10);
      }
      return null;
    },
    sortedMappedRules: (state) => {
      if (!state.selectedDryRun || !state.selectedDryRun.mapping) {
        return [];
      }
      return [...state.selectedDryRun.mapping.rules].sort((ruleA, ruleB) => (ruleB.rule.priority - ruleA.rule.priority))
    },
    sortedPlanRules: (state) => {
      if (!state.item) {
        return [];
      }
      return [...state.item.planRules].sort((a, b) => b.priority - a.priority);
    },
    dryRunsByPlanId: state => id => {
      return state.dryRuns[id] ? state.dryRuns[id] : []
    }
  }
};

export default plansOverview;
