import Vue from "vue";
import Vuex from "vuex";
import requestItemsOverview from "@/store/modules/requestItemsOverview.js";
import cratesOverview from "@/store/modules/cratesOverview.js";
import plansOverview from "@/store/modules/plansOverview.js";
import insightsOverview from "@/store/modules/insightsOverview";
import locationsList from "@/store/modules/locationsList.js";
import dialogOverview from "@/store/modules/dialogOverview.js";
import reports from "@/store/modules/reports.js";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    requestItemsOverview,
    cratesOverview,
    plansOverview,
    insightsOverview,
    locationsList,
    reports,
    dialogOverview
  },
  strict: debug,
  plugins: debug ? [Vuex.createLogger()] : [],
  getters: {}
});

export default store;
