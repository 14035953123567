import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "@tt/vue-auth0";
import i18n from "@/plugins/i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/request-items",
    showIcon: false,
    active: false
  },
  {
    path: "/request-items",
    name: "requestItemsOverview",
    menuTitle: i18n.tc('request_items.title', 2),
    menuIcon: "mdi-folder-clock-outline",
    showIcon: false,
    component: () => import("@/views/RequestItemsView.vue"),
    active: true
  },
  {
    path: "/crates",
    name: "cratesOverview",
    menuTitle: i18n.tc('crates.title', 2),
    menuIcon: "mdi-folder-clock-outline",
    showIcon: false,
    component: () => import("@/views/CratesView.vue"),
    active: true
  },
  {
    path: "/plans",
    name: "plansOverview",
    menuTitle: i18n.tc("plans.title", 2),
    menuIcon: "mdi-ballot-outline",
    showIcon: false,
    component: () => import("@/views/PlansView.vue"),
    active: true
  },
  {
    path: "/insights",
    name: "insightsOverview",
    menuTitle: i18n.tc("insights.title", 2),
    menuIcon: "mdi-chart-bar",
    showIcon: false,
    component: () => import("@/views/InsightsView.vue"),
    active: true
  }

];

// create router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// auth check
router.beforeEach(authGuard);

export default router;
