import createClient from "@tt/vue-components/src/services/apiClient.js";
import EnvProvider from "@tt/vue-environment/EnvProvider";
import baseApiService from "@tt/vue-components/src/services/baseApiService.js";
const apiClient = createClient(EnvProvider.value("ALLOCATION_API_URL"));

const AllocationService = {
  requests: {
    ...baseApiService(apiClient, 'requests'),
  },
  dry_run: {
    ...baseApiService(apiClient, 'requests/dry_run'),
  },
  requestItems: {
    ...baseApiService(apiClient, 'request_items'),
    reset: async (id, body) => {
      let url = "request_items/" + id + "/reset";
      return await apiClient.put(url, body);
    }
  },
  tickets: {
    ...baseApiService(apiClient, 'tickets'),
    reset: async (id) => {
      let url = "tickets/" + id + "/status";
      let body = {
        status: "requested",
        ticketLocation: null
      }
      return await apiClient.update(url, body);
    }
  },
  ticketLocation: {
    statistics: async () => {
      let url = "ticket_locations:statistics";
      return await apiClient.get(url);
    }
  },
  plans: {
    ...baseApiService(apiClient, 'plans')
  },
  rules: {
    ...baseApiService(apiClient, 'rules')
  },
  conditions: {
    ...baseApiService(apiClient, 'conditions')
  },
  planRules: {
    ...baseApiService(apiClient, 'plan_rules')
  },
  crates: {
    ...baseApiService(apiClient, 'crates')
  },
  crateItems: {
    ...baseApiService(apiClient, 'crate_items'),
  },
  reports: {
    ...baseApiService(apiClient, 'reports'),
    regenerateReport: async (body) => {
      return await apiClient.post("report_time_frames:regenerate_report", body)
    }
  },
  reportTimeframes: {
    ...baseApiService(apiClient, 'report_time_frames'),
  },
  timeframes: {
    ...baseApiService(apiClient, 'time_frames'),
  }
}

export default AllocationService;
