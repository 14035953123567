const isClosedFilter = {
    namespaced: true,
    state: {
        data: null,
    },
    mutations: {
        SET_DATA(state, data) {
            state.data = data;
        }
    },
    actions: {
        setData(context, value) {
            context.commit("SET_DATA", value);
        }
    },
    getters: {
        data: state => {
            return state.data;
        },
        toParams: state => {
            if (state.data) {
                return [
                    {column: "exists[closedDt]", value: state.data}
                ];
            }
        }
    }
};

export default isClosedFilter;
