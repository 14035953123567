import { tableState, tableActions, tableMutations, tableGetters } from "@tt/vue-components";
import AllocationService from "@/services/AllocationService.js";
import history from "@/store/modules/requestItemsOverview/tickets/history.js";

const tickets = {
    namespaced: true,
    modules: {
      history
    },
    state: {
        ...tableState,
        sorting: { sortBy: ["createdDt"], sortDesc: [true] },
        ticket: null
    },
    mutations: {
        ...tableMutations,
        SET_TICKET(state, ticket) {
            state.ticket = ticket
        }
    },
    actions: {
        ...tableActions,
        fetchItems(context, refresh = false) {
            if (refresh || context.state.items.length === 0) {
                context.commit("SET_LOADING", true);
                AllocationService.tickets.list({
                    pagination: { page: 1, itemsPerPage: 1000 },
                    sorting: context.state.sorting,
                    search: [{ column: "requestItem", value: context.rootState.requestItemsOverview.requestItem.id }]
                }).then(json => {
                    context.commit("SET_ITEMS", json);
                    context.commit("SET_LOADING", false);
                })
                .catch(err => {
                    context.commit("SET_LOADING", false);
                    console.log(err);
                });
            }
        },
        reset(context, item) {
            AllocationService.tickets.reset(item.id).then(() => {
                context.dispatch("tickets/fetchItems", true);
            });
        },
        setTicket(context, ticket) {
            context.commit("SET_TICKET", ticket);
        },
    },
    getters: {
        ...tableGetters,
        itemsSorted: state => {
            return [...state.items].sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.createdDt) - new Date(b.createdDt);
            });
        }
    }
};

export default tickets;
