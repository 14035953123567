import Vue from "vue";
import VuetifyConfirm from "vuetify-confirm";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: i18n.t("app.confirm.btn.yes"),
  buttonFalseText: i18n.t("app.confirm.btn.no"),
  title: i18n.t("app.confirm.warning")
});