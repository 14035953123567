import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import store from "@/store";
import "@/plugins/tt.js";
import i18n from "@/plugins/i18n";
import "@/plugins/auth0.js";
import "@/plugins/vuetify-notify.js"
import "@/plugins/ttApi.js";
import "@/plugins/confirm.js";
import "@/plugins/tasks.js"
import "@/plugins/jsoneditor.js";
import "@/plugins/countryflag.js";
import "@/plugins/jsonviewer.js";
import "@/plugins/language.js"
import "@/plugins/slideout.js"

Vue.config.productionTip = false;
new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
