<template>
  <v-app>
    <AppBar
      v-if="$auth.isAuthenticated"
      color="white"
      :portal-url="portalUrl"
      :buttons="moduleButtons"
      :menu-items="menuItems"
    >
      <template v-slot:application-buttons>
        <TaskStatus :close-delay="10000" :update-interval="20000" />
      </template>
    </AppBar>

    <v-main>
      <Message />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "@tt/vue-components/src/components/AppBar";
import EnvProvider from "@tt/vue-environment/EnvProvider";
export default {
  name: "App",
  components:  {AppBar},
  data: () => ({
    portalUrl: EnvProvider.value("PORTAL_URL"),
    moduleButtons: [
      // {
      //   code: "notifications",
      //   show: false,
      //   icon: "mdi-bell-outline",
      //   label: vm.$t("app.module.btn.notifications", "Notifications"),
      //   route: "",
      //   showBadge: true
      // },
      // {
      //   code: "config",
      //   show: false,
      //   icon: "mdi-cog-outline",
      //   label: vm.$t("app.module.btn.config", "Configuration"),
      //   route: "",
      //   showBadge: false
      // }
    ]
  }),
  computed: {
    menuItems() {
      return this.$router.options.routes
        .filter(item => {
          return !(item.menuTitle === undefined && item.menuIcon === undefined);
        })
        .filter(item => {
          return item.active === true;
        });
    }
  }
};
</script>
