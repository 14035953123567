import {
    tableState,
    tableMutations,
    tableActions,
    tableGetters
} from "@tt/vue-components";
import allocationService from "@/services/AllocationService.js";

const rulesOverview = {
    namespaced: true,
    state: {
        ...tableState,
        rule: {},
        backup: {}
    },
    mutations: {
        ...tableMutations,
        SET_ITEMS(state, data) {
            state.items = data.items;
        },
        SET_RULE(state, rule) {
            state.rule = structuredClone(rule);
            state.backup = rule;
        },
        REVERT_RULE(state) {
            state.rule = structuredClone(state.backup);
        }
    },
    actions: {
        ...tableActions,
        fetchItems(context, refresh = false) {
            if (refresh || context.state.items.length === 0) {
                context.commit("SET_ERROR", false);
                context.commit("SET_LOADING", true);
                const params = { pagination: context.state.pagination, sorting: context.state.sorting, search: context.state.search };
                allocationService.rules.list(params)
                    .then(json => {
                        context.commit("SET_ITEMS", json);
                        context.commit("SET_LOADING", false);
                    })
                    .catch(err => {
                        context.commit("SET_ERROR", true);
                        context.commit("SET_LOADING", false);
                        console.log(err);
                    });
            }
        },
        searchItems(context, event) {
            if (event) {
                context.commit('SET_SEARCH', [{ column: "name", value: event}]);
            } else {
                context.commit('SET_SEARCH', []);
            }
            context.dispatch("fetchItems", true);
        },
        addPlanRule(context, { plan, rule }) {
            context.commit("SET_ERROR", false);
            context.commit("SET_LOADING", true);
            const params = {
                plan: plan['@id'],
                rule: rule['@id'],
                name: rule.name,
                description: rule.description,
                arguments: rule.arguments,
                condition: rule.condition,
                priority: rule.priority
            }
            return new Promise((resolve, reject) => {
                allocationService.planRules.create(params)
                    .then(result => {
                        context.commit("SET_LOADING", false);
                        context.commit("plansOverview/ADD_PLAN_RULE", result, { root: true })
                        resolve(result);
                    })
                    .catch(err => {
                        context.commit("SET_ERROR", true);
                        context.commit("SET_LOADING", false);
                        console.log(err);
                        reject()
                    })
            })
        },
        editPlanRule(context, { plan, rule }) {
            context.commit("SET_ERROR", false);
            context.commit("SET_LOADING", true);
            const params = {
                ...rule,
                condition: rule.condition ?
                    typeof(rule.condition) === 'string' ? rule.condition : rule.condition['@id'] : null,
                plan: plan['@id'],
                rule: rule.rule['@id']
            }
            return new Promise((resolve, reject) => {
                allocationService.planRules.update(params.id, params)
                    .then((result) => {
                        context.commit("SET_LOADING", false);
                        context.commit("plansOverview/UPDATE_PLAN_RULE", result, { root: true })
                        resolve(result);
                    })
                    .catch(err => {
                        context.commit("SET_ERROR", true);
                        context.commit("SET_LOADING", false);
                        console.log(err);
                        reject()
                    });
            });
        },
        deletePlanRule(context, id) {
            context.commit("SET_ERROR", false);
            context.commit("SET_LOADING", true);
            return new Promise((resolve, reject) => {
                allocationService.planRules.delete(id)
                    .then(() => {
                        context.commit("SET_LOADING", false);
                        context.commit("plansOverview/DELETE_PLAN_RULE", id, { root: true })
                        resolve();
                    })
                    .catch(err => {
                        context.commit("SET_ERROR", true);
                        context.commit("SET_LOADING", false);
                        console.log(err);
                        reject(err["hydra:description"]);
                    });
            });
        },
    },
    getters: {
        ...tableGetters,
        requiredRules: (state) => {
            return state.items;
        }
    }
};

export default rulesOverview;
