import {
    tableState,
    tableMutations,
    tableActions,
    tableGetters
} from "@tt/vue-components";
import allocationService from "@/services/AllocationService.js";

const conditionsOverview = {
    namespaced: true,
    state: {
        ...tableState
    },
    mutations: {
        ...tableMutations,
        SET_ITEMS(state, data) {
            state.items = data.items;
        }
    },
    actions: {
        ...tableActions,
        fetchItems(context, refresh = false) {
            if (refresh || context.state.items.length === 0) {
                context.commit("SET_ERROR", false);
                context.commit("SET_LOADING", true);
                const params = { pagination: context.state.pagination, sorting: context.state.sorting, search: context.state.search };
                allocationService.conditions.list(params)
                    .then(json => {
                        context.commit("SET_ITEMS", json);
                        context.commit("SET_LOADING", false);
                    })
                    .catch(err => {
                        context.commit("SET_ERROR", true);
                        context.commit("SET_LOADING", false);
                        console.log(err);
                    });
            }
        },
        searchItems(context, event) {
            if (event) {
                context.commit('SET_SEARCH', [{ column: "name", value: event}]);
            } else {
                context.commit('SET_SEARCH', []);
            }
            context.dispatch("fetchItems", true);
        },
    },
    getters: {
        ...tableGetters
    }
};

export default conditionsOverview;
