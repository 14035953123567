import { tableState, tableMutations, tableActions, tableGetters } from "@tt/vue-components";
import skuFilter from "@/store/modules/cratesOverview/filter/skuFilter.js";
import referenceFilter from "@/store/modules/cratesOverview/filter/referenceFilter.js";
import locationsFilter from "@/store/modules/cratesOverview/filter/locationsFilter.js";
import isClosedFilter from "@/store/modules/cratesOverview/filter/isClosedFilter.js";
import allocationService from "@/services/AllocationService.js";

const cratesOverview = {
  requestController: null,
  namespaced: true,
  state: {
    ...tableState,
    filters: [],
    defaultFilters: [],
    showDetails: false,
    crate: null
  },
  modules: {
    skuFilter,
    referenceFilter,
    locationsFilter,
    isClosedFilter
  },
  mutations: {
    ...tableMutations,
    SET_SHOW_DETAILS(state, showDetails) {
      state.showDetails = showDetails;
    },
    SET_CRATE(state, crate) {
      state.crate = crate;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        const params = { pagination: context.state.pagination, sorting: context.state.sorting, search: context.state.search };
        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();
        allocationService.crates.list(params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              context.commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            context.commit("SET_LOADING", false);
          });
      }
    },
    setShowDetails(context, showDetails) {
      context.commit("SET_SHOW_DETAILS", showDetails);
    },
    setCrate(context, requestItem) {
      context.commit("SET_CRATE", requestItem);
    },
    search(context) {
      let params = [];

      if (context.state.defaultFilters.length || context.state.filters.length) {
        let filters = [...context.state.defaultFilters, ...context.state.filters];

        filters.forEach(filter => {
          let filterParams = context.getters[filter.name + "/toParams"];
          if (filterParams !== undefined && filterParams.length > 0) {
            for (let i = 0; i < filterParams.length; i++) {
              params.push(filterParams[i]);
            }
          }
        });
      }
      if (params.length) {
        context.commit("SET_SEARCH", params.filter(param => {
          return param.value !== undefined && param.value !== null && param.value !== "" && param.value !== []
        }));
      }
      context.dispatch("fetchItems", true);
    },
    edit(context, item) {
      allocationService.crates.update(item).then(() => {
        context.commit("SET_CRATE", item);
      });
    }
  },
  getters: {
    ...tableGetters
  }
};

export default cratesOverview;
