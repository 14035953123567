import { tableState, tableMutations, tableActions, tableGetters } from "@tt/vue-components";
import allocationService from "@/services/AllocationService";

const insightsOverview = {
  namespaced: true,
  state: {
    ...tableState,
    filters: [],
    defaultFilters: [],
    showDetails: false,
    report: null,
    timeframe: null
  },
  modules: {
  },
  mutations: {
    ...tableMutations,
    SET_SHOW_DETAILS(state, showDetails) {
      state.showDetails = showDetails;
    },
    SET_REPORT(state, report) {
      state.report = report;
    },
    SET_TIMEFRAME(state, timeframe) {
      state.timeframe = timeframe;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        const params = {
          pagination: context.state.pagination,
          sorting: context.state.sorting,
          search: context.state.search
        };
        allocationService.reportTimeFrames.list(params)
          .then(json => {
            context.commit("SET_ITEMS", json);
            context.commit("SET_LOADING", false);
          })
          .catch(err => {
            context.commit("SET_LOADING", false);
            console.log(err);
          });
      }
    },
    fetchReport({ commit, state }) {
      commit("SET_LOADING", true);
      allocationService.reportTimeFrames.getSubResource(state.timeframe.id, "generated")
        .then(data => {
          commit("SET_REPORT", data);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    },
    setShowDetails(context, showDetails) {
      context.commit("SET_SHOW_DETAILS", showDetails);
    },
    setTimeframe({ commit, dispatch }, timeframe) {
      commit("SET_TIMEFRAME", timeframe);
      dispatch("fetchReport");
    },
    search(context) {
      let params = [];

      if (context.state.defaultFilters.length || context.state.filters.length) {
        let filters = [...context.state.defaultFilters, ...context.state.filters];

        filters.forEach(filter => {
          let filterParams = context.getters[filter.name + "/toParams"];
          if (filterParams !== undefined && filterParams.length > 0) {
            for (let i = 0; i < filterParams.length; i++) {
              params.push(filterParams[i]);
            }
          }
        });
      }
      if (params.length) {
        context.commit("SET_SEARCH", params.filter(param => {
          return param.value !== undefined && param.value !== null && param.value !== "" && param.value !== []
        }));
      }
      context.dispatch("fetchItems", true);
    },
  },
  getters: {
    ...tableGetters
  }
};

export default insightsOverview;
