import allocationService from "@/services/AllocationService.js";

const reportFilter = {
  namespaced: true,
  state: {
    data: null,
    options: [],
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_OPTIONS(state, options) {
      state.options = options;
    },
  },
  actions: {
    setData(context, data) {
      context.commit("SET_DATA", data);
    },
    getOptions(context, refresh) {
      if (refresh || context.state.options.length === 0) {
        const params = { pagination: {page: 1, itemsPerPage: 1000 }, sorting: { sortBy: ["title"], sortDesc: [false]} };
        allocationService.reports.list(params)
          .then(json => {
            context.commit("SET_OPTIONS", json.items);
            if (json.items.length === 1) {
              context.commit("SET_DATA", json.items[0].id);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
  },
  getters: {
    data: state => {
      return state.data;
    },
    options: state => {
      return state.options.map(option => {
        return {
          text: option.title,
          value: option.id,
        };
      });
    },
    toParams: state => {
      return { column: "report.id", value: state.data };
    },
    selectedOptionByValue: state => {
      return state.options.find(option => option.id === state.data);
    }
  }
};

export default reportFilter;
