import Vue from 'vue';

const dialogOverview = {
    namespaced: true,
    state: {
        dialogStatus: {},
        dialogData: {},
        dialogText: {}
    },
    modules: {
    },
    getters: {
        getDialogDataById: (state) => (id) => {
            return state.dialogData[id];
        },
        getDialogStatusById: (state) => (id) => {
            return state.dialogStatus[id];
        }
    },
    mutations: {
        CLOSE_DIALOG_BY_ID: (state, id) => {
            Vue.set(state.dialogStatus, id, false);
        },
        OPEN_DIALOG_BY_ID: (state, id) => {
            Vue.set(state.dialogStatus, id, true);
        },
        SET_DATA: (state, { id, data }) => {
            Vue.set(state.dialogData, id, data);
        },
        SET_TEXT: (state, { id, data }) => {
            Vue.set(state.dialogText, id, data);
        }
    },
    actions: {
    }
};

export default dialogOverview;
