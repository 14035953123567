import Vue from "vue";
import Vuetify from "vuetify/lib";
import { preset } from '@tt/vue-cli-plugin-vuetify-preset-tt/preset'

Vue.use(Vuetify);

export default new Vuetify({
  preset,
  theme: {
    customProperties: true,
    light: true
  },
});
